
import { Component, Prop, Provide, Vue, Watch } from "vue-property-decorator";
import html2canvas from "html2canvas";
import { UploadFileByBase64Api } from "@/network/modules/common";
import { UploadFileByBase64Req } from "@/interface/res/common";
import { Drag } from "@/views/Index/Customization/js/drag";
// import {consoleLog} from "echarts/types/src/util/log";

@Component({
  components: {},
  computed: {},
  directives: {
    Drag,
  },
})
export default class Step extends Vue {
  @Prop({ type: Number, default: 0 }) readonly menuIndex!: number;
  @Prop({ type: Object, default: () => {} }) readonly mainItem!: any;
  @Prop({ type: Array, default: [] }) readonly xiuList!: Array<any>;
  @Prop({ type: Array, default: [] }) readonly fuList!: Array<any>;
  @Prop({ type: Number, default: 0 }) readonly step!: number;
  @Prop({ type: Boolean, default: false }) readonly isXiu!: boolean;

  @Provide() isEntiretyEdit: boolean = false;
  @Provide() isXiuEdit: boolean = false;
  @Provide() diyIndex: number = 0;

  @Provide() element: any = null;
  @Provide() only: boolean = false;
  @Provide() elementBorder: any = null;
  @Provide() minNum: number = 50;
  @Provide() rotate: number = 5;
  @Provide() scale: number = 30;

  @Provide() coreScale: { transform: string } = { transform: "" };
  @Provide() mainBack: {
    backgroundImage: string;
    width: string;
    height: string;
  } = { backgroundImage: "", width: "", height: "" };
  @Provide() maskingBack: {
    backgroundImage: string;
    width: string;
    height: string;
  } = { backgroundImage: "", width: "", height: "" };
  @Provide() boderParentStyle: { top: string; left: string } = {
    top: "",
    left: "",
  };
  @Provide() boderStyle: {
    top: string;
    left: string;
    width: string;
    height: string;
    transform: string;
  } = { top: "", left: "", width: "", height: "", transform: "" };

  @Provide() saveImgLoading: boolean = false;
  @Provide() confirmPlanLoading: boolean = false;
  @Provide() saveImgType: number = 0;
  @Provide() dialogImgShow: boolean = false;
  @Provide() videoDialog: boolean = false;
  @Provide() dialogImgSrc: string = "";

  @Watch("menuIndex", { immediate: true, deep: false })
  private iValChange(newVal: number): void {
    const _this: any = this;

    if (newVal < 0) return;
    if (_this.only == false) {
      _this.getElc();
    }
    if (newVal == 0) {
      _this.isEntiretyEdit = false;
      _this.isXiuEdit = false;
      return;
    }

    if (newVal > 0 && newVal <= _this.xiuList.length) {
      _this.isEntiretyEdit = true;
      _this.isXiuEdit = true;
      _this.diyIndex = newVal - 1;
      return;
    }
    if (newVal > _this.xiuList.length) {
      _this.isEntiretyEdit = true;
      _this.isXiuEdit = false;
      _this.diyIndex = newVal - _this.xiuList.length - 1;
      return;
    }
  }

  @Watch("mainItem", { immediate: false, deep: false })
  private mainItemChange(newVal: any): void {
    const _this: any = this;
    _this.coreScale = { transform: "scale(" + newVal.scale + ")" };
    _this.mainBack = {
      backgroundImage: "url(" + newVal.galleryImg + ")",
      width: newVal.galleryWidth + "px",
      height: newVal.galleryHeight + "px",
    };
    _this.maskingBack = {
      backgroundImage: "url(" + newVal.galleryMaskImg + ")",
      width: newVal.galleryWidth + "px",
      height: newVal.galleryHeight + "px",
    };

    if (_this.only) return;
    _this.getElc();
  }

  @Watch("xiuList", { immediate: false, deep: false })
  @Watch("fuList", { immediate: false, deep: false })
  private listChange(): void {
    const _this: any = this;
    if (_this.menuIndex <= 0) return;
    _this.element = document.querySelector(".imgdiy" + _this.menuIndex);
    if (_this.$base.isNull(_this.element)) {
      if (_this.only) return;
      _this.getElc();
      return;
    }
    let img = _this.element.querySelector("img");
    let item: any = {};
    if (_this.menuIndex > 0 && _this.menuIndex <= _this.xiuList.length) {
      item = _this.xiuList[_this.diyIndex];
      // 修改重新选择位置偏移bug
      _this.element.style.top = item.height / 2 + "px";
      _this.element.style.left = item.width / 2 + "px";
      _this.element.style.width = item.data.galleryWidth + "px";
      _this.element.style.height = item.data.galleryHeight + "px";
      _this.element.style.transform =
        "translateX(-50%) translateY(-50%) rotateZ(0deg) rotateY(0deg)";
      img.style.width = item.data.galleryWidth + "px";
      img.style.height = item.data.galleryHeight + "px";
    } else if (_this.menuIndex > _this.xiuList.length) {
      item = _this.fuList[_this.diyIndex];
      if (_this.$base.isNull(item)) return;
      _this.element.style.width = item.data.galleryWidth + "px";
      _this.element.style.height = item.data.galleryHeight + "px";
      _this.element.style.top = item.data.galleryHeight / 2 + "px";
      _this.element.style.left = item.data.galleryWidth / 2 + "px";
      img.style.width = item.data.galleryWidth + "px";
      img.style.height = item.data.galleryHeight + "px";
    }

    if (_this.only) return;
    _this.getElc();
  }

  @Watch("dialogImgSrc", { immediate: false, deep: false })
  private saveImgSrcChange(newVal: string): void {
    const _this: any = this;
    if (_this.$base.isNull(newVal)) return;
    if (_this.$base.isNull(_this.saveImgType)) {
      _this.saveImgLoading = false;
      _this.confirmPlanLoading = false;
      _this.goDetails();
      return;
    }
    let newImg = new Image();
    newImg.src = newVal;
    newImg.onload = () => {
      _this.dialogImgShow = true;
      _this.saveImgLoading = false;
      _this.confirmPlanLoading = false;
    };
  }

  created() {
    const _this: any = this;
  }
  mounted() {
    const _this: any = this;
  }
  dialogCloseVideo(){
    console.log('object');
    const _this: any = this;
    this.videoDialog=false; 
    _this.$refs.vueRef.pause();
  }
  /**
   * @Author HS
   * @Date 2021/8/19 6:01 下午
   * @Description: 确认方案
   * @Params: null
   * @Return: null
   */
  confirmPlanFn() {
    const _this: any = this;
    if (
      _this.step > 2 &&
      _this.isXiu == true &&
      _this.confirmPlanLoading == false
    ) {
      _this.saveImgType = 0;
      _this.confirmPlanLoading = true;
      _this.generatePictureFn();
    }
  }

  /**
   * @Author HS
   * @Date 2021/8/20 4:06 下午
   * @Description: 保存图片
   * @Params: null
   * @Return: null
   */
  saveImgFn() {
    const _this: any = this;
    if (_this.step > 1 && _this.saveImgLoading == false) {
      _this.saveImgType = 1;
      _this.saveImgLoading = true;
      _this.generatePictureFn();
    }
  }

  /**
   * @Author HS
   * @Date 2021/8/19 6:01 下午
   * @Description: 生成图片
   * @Params: null
   * @Return: null
   */
  generatePictureFn() {
    const _this: any = this;
    _this.$emit("menuSwitch", 0);
    let canvas2 = document.createElement("canvas");
    let _canvas: any = document.getElementById("diyImg");
    let w = parseInt(_canvas.getBoundingClientRect().width);
    let h = parseInt(_canvas.getBoundingClientRect().height);
    //将canvas画布放大若干倍，然后盛放在较小的容器内，就显得不模糊了
    // window.devicePixelRatio 高倍屏
    canvas2.width = w * window.devicePixelRatio;
    canvas2.height = h * window.devicePixelRatio;
    canvas2.style.width = w * window.devicePixelRatio + "px";
    canvas2.style.height = h * window.devicePixelRatio + "px";
    //可以按照自己的需求，对context的参数修改,translate指的是偏移量
    let context: any = canvas2.getContext("2d");
    context.translate(0, 0);
    let a = setTimeout(() => {
      html2canvas(_canvas, {
        async: false,
        tainttest: true,
        canvas: canvas2,
        scrollY: -window.pageYOffset,
        scrollX: -window.pageXOffset - 8,
        useCORS: true,
        backgroundColor: "#746F6C",
      }).then((canvas: HTMLCanvasElement) => {
        const data = canvas.toDataURL("image/png");
        _this.UploadFileByBase64Fn(data);
      });
      clearTimeout(a);
    }, 300);
  }

  /**
   * @Author HS
   * @Date 2021/8/19 5:55 下午
   * @Description: 下载图片
   * @Params: null
   * @Return: null
   */
  downloadImgCallbackFn() {
    const _this: any = this;
    let link = document.createElement("a");
    link.style.display = "none";

    let imgUrl =
      _this.dialogImgSrc +
      "?x-oss-process=image/watermark,image_aWNvbi93YXRlcm1hcmsucG5nP3gtb3NzLXByb2Nlc3M9aW1hZ2UvcmVzaXplLFBfMTQ,t_100,g_sw,x_0,y_16";

    console.log(
      "%c [_this.dialogImgSrc]: ",
      "color: #bf2c9f; background: pink; font-size: 13px;",
      _this.dialogImgSrc
    );
    fetch(imgUrl)
      .then((res) => res.blob())
      .then((blob) => {
        link.href = URL.createObjectURL(blob);
        link.download = "download.jpg";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }

  /**
   * @Author HS
   * @Date 2021/8/16 5:15 下午
   * @Description: 元素编辑
   * @Params: { number ：iType - true } [编辑类型：1-左右翻转 2-向右旋转 3-向左旋转 4-放大 5-缩小 6-还原编辑 7-整体编辑]
   * @Return: null
   */
  elementEditFn(iType: number) {
    const _this: any = this;

    switch (iType) {
      case 1:
        _this.elOverturnFn();
        break;
      case 2:
        _this.elRotateFn(0);
        break;
      case 3:
        _this.elRotateFn(1);
        break;
      case 4:
        _this.elBtnScaleFn(0);
        break;
      case 5:
        _this.elBtnScaleFn(1);
        break;
      case 6:
        _this.elResetFn();
        break;
      case 7:
        _this.$emit("menuSwitch", 0);
        break;
    }
  }

  /**
   * @Author HS
   * @Date 2021/9/2 10:04 上午
   * @Description: 翻转
   * @Params: null
   * @Return: null
   */
  elOverturnFn() {
    const _this: any = this;
    if (_this.$base.isNull(_this.element)) return;
    let flag = _this.element.getAttribute("ctype");
    let str = _this.element.style.transform;
    switch (flag) {
      case "main":
        if (str && str.indexOf("180") > -1) {
          _this.element.style.transform = "rotateY(0deg)";
        } else {
          _this.element.style.transform = "rotateY(180deg)";
        }
        break;
      case "xiu":
        let arr = str.split(" ");
        let i0 = arr.indexOf("rotateY(0deg)");
        let i180 = arr.indexOf("rotateY(180deg)");
        let i = i0 == -1 ? i180 : i0;
        arr[i] = i0 > -1 ? "rotateY(180deg)" : "rotateY(0deg)";
        _this.element.style.transform = arr.join(" ");
        break;
      case "fu":
        if (str && str.indexOf("180") > -1) {
          _this.element.style.transform =
            "rotateY(0deg) translateX(-50%) translateY(-50%)";
        } else {
          _this.element.style.transform =
            "rotateY(180deg) translateX(50%) translateY(-50%) ";
        }
        break;
    }
  }

  /**
   * @Author HS
   * @Date 2021/9/2 10:12 上午
   * @Description: 旋转
   * @Params: { number ：iType - true } [旋转：0-向右旋转 1-向左旋转]
   * @Return: null
   */
  elRotateFn(iType: number) {
    const _this: any = this;
    if (_this.$base.isNull(_this.element)) return;
    if (_this.element.getAttribute("ctype") != "xiu") return;
    let rate = iType == 0 ? _this.rotate : -_this.rotate;
    let str = _this.element.style.transform;
    let arr = str.split(" ");
    let late = "",
      i = null;
    let main: any = document.querySelector(".imgdiy0");

    if (main.style.transform.indexOf("rotateY(180deg)") > -1) {
      rate = -rate;
    }

    arr.map((item: any, index: number) => {
      if (item.indexOf("rotateZ") > -1) {
        late = item;
        i = index;
      }
    });
    let num = parseInt(late.slice(8, -4));
    if (i !== null) {
      num += rate;
      arr[i] = `rotateZ(${num}deg)`;
    }
    _this.element.style.transform = arr.join(" ");
  }

  /**
   * @Author HS
   * @Date 2021/9/2 2:46 下午
   * @Description: 放大缩小
   * @Params: { number ：iType - true } [旋转：0-放大 1-缩小]
   * @Return: null
   */
  elBtnScaleFn(iType: number) {
    const _this: any = this;
    if (_this.$base.isNull(_this.element)) return;
    let step = iType == 0 ? _this.scale : -_this.scale;

    if (_this.element.getAttribute("ctype") == "main") {
      let scale = _this.element.parentElement.style.transform;
      scale = parseFloat(scale.slice(6, -1));
      scale = scale + step / 1000 < 0.001 ? scale : scale + step / 1000;
      _this.element.parentElement.style.transform = `scale(${scale})`;
      return;
    }

    let rate = _this.element.getAttribute("rate");
    let img = _this.element.querySelector("img");
    let width = img.width;
    let imgWidth = 0,
      imgHeight = 0;
    if (parseFloat(_this.elementBorder.style.width) <= _this.minNum && step < 0)
      return;
    if (
      parseFloat(_this.elementBorder.style.height) <= _this.minNum &&
      step < 0
    )
      return;
    imgWidth = width + step;
    imgHeight = imgWidth / rate;
    _this.element.style.width =
      _this.elementBorder.style.width =
      img.style.width =
        imgWidth + "px";
    _this.element.style.height =
      _this.elementBorder.style.height =
      img.style.height =
        imgHeight + "px";
  }

  /**
   * @Author HS
   * @Date 2021/9/2 4:14 下午
   * @Description: 还原编辑
   * @Params: null
   * @Return: null
   */
  elResetFn() {
    const _this: any = this;
    if (_this.$base.isNull(_this.element)) return;
    let item: any = null;

    if (_this.element.getAttribute("ctype") == "main") {
      item = _this.mainItem;
      _this.element.parentElement.style.transform = `scale(${item.scale})`;
      _this.element.style.width = item.galleryWidth + "px";
      _this.element.style.height = item.galleryHeight + "px";
      _this.element.style.left = item.left + "px";
      _this.element.style.top = item.top + "px";
      return;
    }

    let oImg = _this.element.querySelector("img");
    if (_this.element.getAttribute("ctype") == "xiu") {
      item = _this.xiuList[_this.diyIndex];
      _this.elementBorder.style.width =
        _this.element.style.width =
        oImg.style.width =
          item.data.galleryWidth + "px";
      _this.elementBorder.style.height =
        _this.element.style.height =
        oImg.style.height =
          item.data.galleryHeight + "px";
      _this.elementBorder.style.left = _this.element.style.left =
        item.width / 2 + "px";
      _this.elementBorder.style.top = _this.element.style.top =
        item.height / 2 + "px";
      _this.element.style.transform =
        "translateX(-50%) translateY(-50%) rotateZ(0deg) rotateY(0deg)";
      return;
    }

    if (_this.element.getAttribute("ctype") == "fu") {
      item = _this.fuList[_this.diyIndex].data;
      _this.elementBorder.style.width =
        _this.element.style.width =
        oImg.style.width =
          item.galleryWidth + "px";
      _this.elementBorder.style.height =
        _this.element.style.height =
        oImg.style.height =
          item.galleryHeight + "px";
      _this.elementBorder.style.left = _this.element.style.left =
        item.galleryWidth / 2 + "px";
      _this.elementBorder.style.top = _this.element.style.top =
        item.galleryHeight / 2 + "px";
      _this.element.style.transform =
        "translateX(-50%) translateY(-50%) rotateZ(0deg) rotateY(0deg)";
      return;
    }
  }

  /**
   * @Author HS
   * @Date 2021/8/31 10:13 上午
   * @Description: 辅单元大小/位置
   * @Params: { number ：index - true } [辅单元下标]
   * @Return: null
   */
  fuItemStyle(index: number) {
    const _this: any = this;
    const data = _this.fuList[index].data;
    if (_this.$base.isNull(data)) return;
    return {
      width: data.galleryWidth + "px",
      height: data.galleryHeight + "px",
      left: data.galleryWidth / 2 + "px",
      top: data.galleryHeight / 2 + "px",
      transform:
        "translateX(-50%) translateY(-50%) rotateZ(0deg) rotateY(0deg)",
    };
  }

  /**
   * @Author HS
   * @Date 2021/8/31 10:13 上午
   * @Description: 绣品大小/位置
   * @Params: { number ：index - true } [绣品下标]
   * @Return: null
   */
  xiuItemStyle(index: number) {
    const _this: any = this;
    const data = _this.xiuList[index];
    if (_this.$base.isNull(data.data)) return;
    return {
      width: data.data.galleryWidth + "px",
      height: data.data.galleryHeight + "px",
      left: data.width / 2 + "px",
      top: data.height / 2 + "px",
      transform:
        "translateX(-50%) translateY(-50%) rotateZ(0deg) rotateY(0deg)",
    };
  }

  // 绣品图片大小样式
  xiuItemImgStyle(index: number) {
    const _this: any = this;
    const data = _this.xiuList[index];
    if (_this.$base.isNull(data.data)) return;
    return {
      width: data.data.galleryWidth + "px",
      height: data.data.galleryHeight + "px",
    };
  }

  /**
   * @Author HS
   * @Date 2021/8/30 5:50 下午
   * @Description: 主单元/绣品/辅单元 位置、大小
   * @Params: { object ：data - true } [当前元素]
   * @Return: null
   */
  diyStyle(data: any) {
    const _this: any = this;
    if (_this.$base.isNull(data.data)) {
      return {
        width: data.galleryWidth + "px",
        height: data.galleryHeight + "px",
        left: data.left + "px",
        top: data.top + "px",
      };
    }

    if (_this.$base.isNull(data.leftWidth)) {
      return {
        width: data.data.galleryWidth + "px",
        height: data.data.galleryHeight + "px",
        left: data.data.left + "px",
        top: data.data.top + "px",
      };
    }

    return {
      width: data.width + "px",
      height: data.height + "px",
      left: data.leftWidth + "px",
      top: data.topHeight + "px",
    };
  }

  /**
   * @Author HS
   * @Date 2021/8/31 12:01 下午
   * @Description: 获取选中元素
   * @Params: null
   * @Return: null
   */
  getElc() {
    const _this: any = this;
    _this.only = true;
    _this.$nextTick(() => {
      _this.element = document.querySelector(".imgdiy" + _this.menuIndex);
      if (_this.menuIndex == 0) {
        _this.only = false;
        return;
      }
      if (_this.$base.isNull(_this.element)) {
        _this.only = false;
        return;
      }
      _this.elementBorder = document.querySelector(".diyimgborder");
      let elementParent: any = document.querySelector(
        ".imgparentdiy" + _this.menuIndex
      );
      let sStyle = _this.element.getAttribute("style");
      let oStyle = _this.$base.serializeJson(sStyle, "; ", ": ");

      _this.boderParentStyle = {
        top: elementParent.offsetTop + "px",
        left: elementParent.offsetLeft + "px",
      };
      // oStyle.transform = oStyle.transform.split(';')[0]
      let main: any = document.querySelector(".imgdiy0");
      if (main.style.transform.indexOf("rotateY(180deg)") > -1) {
        oStyle.transform = "translateX(-50%) translateY(-50%) rotateY(180deg)";
      } else {
        oStyle.transform = "translateX(-50%) translateY(-50%) rotateY(0deg)";
      }

      _this.boderStyle = { ...oStyle };
      _this.elementBorder.style.left = oStyle.left;
      _this.elementBorder.style.top = oStyle.top;
      _this.elementBorder.style.width = oStyle.width;
      _this.elementBorder.style.height = oStyle.height;
      _this.only = false;
    });
  }

  /**
   * @Author HS
   * @Date 2021/8/23 10:52 上午
   * @Description: base64上传
   * @Params: { string ：str - true } [base64]
   * @Return: null
   */
  async UploadFileByBase64Fn(str: string) {
    const _this: any = this;
    let params: UploadFileByBase64Req = {
      base64String: str,
    };
    const res = await UploadFileByBase64Api(params);
    const { code, data, msg } = res;
    if (code != 200) {
      _this.$message.error(msg);
      return;
    }
    _this.dialogImgSrc = data;
  }

  /**
   * @Author HS
   * @Date 2021/8/23 10:54 上午
   * @Description: 跳转到详情
   * @Params: null
   * @Return: null
   */
  goDetails() {
    const _this: any = this;
    let imgs: Array<string> = [];
    _this.xiuList.map((item: any) => {
      imgs.push(item.data.galleryImg);
    });
    _this.$setEncryptBy(JSON.stringify(imgs), "_DIYIMGS");
    const link = _this.$base.routeLinkSplice(
      _this.$IndexConfig.diyDetails.path,
      "i=" + _this.dialogImgSrc + "&c=" + _this.mainItem.galleryId
    );
    _this.$base.goRouter(link);
  }
}
