import {Component, Provide, Vue} from 'vue-property-decorator';
import {GetByDictTypeListReq} from "@/interface/res/common";
import {GetByDictTypeListApi} from "@/network/modules/common";
import {ProductMenu} from "@/interface/pagas";
import MainList from "@/views/Index/Customization/Components/MainList.vue";
import XiuList from "@/views/Index/Customization/Components/XiuList.vue";
import FuList from "@/views/Index/Customization/Components/FuList.vue";
import Step from "@/views/Index/Customization/Components/Step.vue";
import DiyImgs from "@/views/Index/Customization/Components/DiyImgs.vue";
import {GetByCodeCustomizationApi} from "@/network/modules";
import {GetByCodeCustomizationReq} from "@/interface/res";
@Component({
  components: {
    MainList,
    XiuList,
    FuList,
    Step,
    DiyImgs
  },
  computed: {
  }
})
export default class Diy extends Vue {
  @Provide() id:number = 0
  @Provide() codeLoading:boolean = false
  @Provide() diyStep:number = 1
  @Provide() menuIndex:number = 0
  @Provide() maxWidth: number = 940
  @Provide() maxHeight: number = 570


  @Provide() mainShow:boolean = false
  @Provide() mainMenuList:Array<ProductMenu> = [{dictValue:"-1",dictLabel:'全部'}]
  @Provide() mainParams:{pageNum:number,galleryShapeType:number,id:number} = {pageNum:1,galleryShapeType:-1,id:-1}
  @Provide() mainItem:any = {}
  @Provide() dialogMainShow:boolean = false
  @Provide() mainReselect:boolean = false

  @Provide() xiuShow:boolean = false
  @Provide() xiuMenuList:Array<ProductMenu> = [{dictValue:"-1",dictLabel:'全部'}]
  @Provide() xiuParams:{pageNum:number,galleryShapeType:number,id:number} = {pageNum:1,galleryShapeType:-1,id:-1}
  @Provide() xiuList:Array<any> = []
  @Provide() isXiu:boolean = false
  @Provide() xiuIndex:number = 0

  @Provide() fuShow:boolean = false
  @Provide() fuList:Array<any> = []
  @Provide() fuParams:{pageNum:number,id:number} = {pageNum:1,id:-1}
  @Provide() fuIndex:number = 0




  created(){
    const _this:any = this;
    if(!_this.$base.isNull(_this.$route.query.i)){
      _this.id = _this.$decryptBy(_this.$route.query.i)
      _this.GetByCodeCustomizationFn()
    }
  }
  mounted(){
    const _this:any = this;
    _this.GetByDictTypeListFn('commodity_type_cxjj')
    _this.GetByDictTypeListFn('commodity_cxjj_shape_type')
  }

  /**
   * @Author HS
   * @Date 2021/8/23 2:40 下午
   * @Description: 获取自定义图片主单元
   * @Params: null
   * @Return: null
  */
  async GetByCodeCustomizationFn(){
    const _this:any = this;
    _this.codeLoading = true
    let params:GetByCodeCustomizationReq = {
      galleryId : _this.id
    }
    const res = await GetByCodeCustomizationApi(params)
    let a = setTimeout(()=>{_this.codeLoading = false;clearTimeout(a)},500)
    const { data, code, msg } = res
    if(code != 200 ){
      _this.$message.error(msg);
      return
    }
    _this.mainCallbackFn(data)

  }



  /**
   * @Author HS
   * @Date 2021/8/13 4:01 下午
   * @Description: 选中辅单元
   * @Params: { object ： data - true } [当前绣品]
   * @Params: { object ： params - true } [绣品列表参数]
   * @Return: null
   */
  fuCallbackFn(data:any,params:any){
    const _this:any = this;
    data.left = 0
    data.top = 0
    data.wch = parseInt(data.galleryWidth) / parseInt(data.galleryHeight)
    _this.diyStep = 4
    if(_this.fuIndex <= -1){
      _this.fuList.push({
        data:data,
        fuParams:params
      })
      _this.fuShow = false
      _this.menuIndex = _this.fuList.length + _this.xiuList.length
      return
    }
    _this.menuIndex = _this.fuIndex + 1 + _this.xiuList.length

    const element:{data?:any,fuParams?:any} = {}
    element.data = data
    element.fuParams = params
    _this.$set(_this.fuList,_this.fuIndex,element)
    _this.fuShow = false
  }
  /**
   * @Author HS
   * @Date 2021/8/13 5:58 下午
   * @Description: 删除辅单元
   * @Params: { number ： index - true } [下标]
   * @Return: null
  */
  deleteFuFn(index:number){
    const _this:any = this;
    _this.menuIndex = 0
    _this.fuList.splice(index,1)
    if(_this.fuList.length <= 0){
      _this.diyStep = 3
    }
  }
  /**
   * @Author HS
   * @Date 2021/8/13 3:47 下午
   * @Description: 打开辅单元弹窗
   * @Params: { number ： index - true } [下标]
   * @Return: null
   */
  fuShowFn(index:number){
    const _this:any = this;
    _this.fuIndex = index
    _this.fuShow = true
    if(_this.$base.isNull(_this.fuList[index]))return
    _this.fuParams = _this.fuList[index].fuParams
  }


  /**
   * @Author HS
   * @Date 2021/8/30 8:07 下午
   * @Description: 补充绣品样式
   * @Params: { object ： data - true } [当前绣品]
   * @Return: null
  */
  xiuInitialStyle(data:any){
    const _this:any = this;
    let width = parseInt(data.galleryWidth),
      height = parseInt(data.galleryHeight),
      cwidth = parseInt(_this.mainItem.galleryValues[_this.xiuIndex].width),
      cheight = parseInt(_this.mainItem.galleryValues[_this.xiuIndex].height);
    let chax = width - cwidth,
      chay = height - cheight;
    let rate = data.galleryWidth / data.galleryHeight;
    // 图片比框小
    if (chax < 0 || chay < 0) {
      if (chax < chay) {
        // 宽的差小于高的差 用宽计算高
        width = cwidth;
        height = width / rate;
      } else {
        // 高的差小于宽的差 用高计算宽
        height = cheight;
        width = height * rate;
      }
    }
    data.wch = rate
    data.galleryWidth = width
    data.galleryHeight = height
    return data
  }
  /**
   * @Author HS
   * @Date 2021/8/13 4:01 下午
   * @Description: 选中绣品
   * @Params: { object ： data - true } [当前绣品]
   * @Params: { object ： params - true } [绣品列表参数]
   * @Return: null
  */
  xiuCallbackFn(data:any,params:any){
    const _this:any = this;
    _this.menuIndex = _this.xiuIndex + 1
    const element = _this.xiuList[_this.xiuIndex]
    element.data = _this.xiuInitialStyle(data)
    element.xiuParams = params
    element.xiu = params
    _this.$set(_this.xiuList,_this.xiuIndex,element)
    let arr =_this.xiuList.filter((item:any) => _this.$base.isNull(item.data))
    _this.xiuShow = false
    if(arr.length <= 0){
      _this.isXiu = true
    }
    else{
      _this.isXiu = false
    }
    if(_this.fuList.length <= 0){
      _this.diyStep = 3
    }
    else{
      _this.diyStep = 4
    }
  }
  /**
   * @Author HS
   * @Date 2021/8/13 3:47 下午
   * @Description: 打开绣品图案弹窗
   * @Params: { number ： index - true } [下标]
   * @Return: null
  */
  xiuShowFn(index:number){
    const _this:any = this;
    _this.xiuIndex = index
    _this.xiuParams = _this.xiuList[index].xiuParams
    _this.xiuShow = true
  }

  /**
   * @Author HS
   * @Date 2021/8/13 2:24 下午
   * @Description: 重新选择主单元
   * @Params: null
   * @Return: null
  */
  reselectMainCallbackFn(){
    const _this:any = this;
    _this.mainShow = true
    _this.dialogMainShow = false
    _this.mainReselect = true
  }
  /**
   * @Author HS
   * @Date 2021/8/13 2:00 下午
   * @Description: 选中主单元
   * @Params: { object ： data - true } [当前主单元]
   * @Return: null
  */
  mainCallbackFn(data:any){
    const _this:any = this;
    _this.mainShow = false
    if(_this.$base.isNull(data)){
      _this.mainItem = {}
      _this.xiuList = []
      _this.fuList = []
      return
    }

    let rw = _this.maxWidth / (data.galleryWidth * 1.1);
    let rh = _this.maxHeight / (data.galleryHeight * 1.1);
    data.scale = rw > rh ? rh : rw;
    data.scale -= 0.05
    data.left = 0
    data.top = 0
    data.wch = parseInt(data.galleryWidth) / parseInt(data.galleryHeight)


    _this.mainItem = data
    _this.diyStep = 2
    if(_this.mainReselect){
      _this.xiuList = []
      _this.fuList = []
    }

    _this.xiuList = data.galleryValues
    _this.xiuList.map((item:any) => {
      item.data = {}
      item.xiuParams = {
        pageNum : 1,
        galleryShapeType : -1,
        id:-1
      }
    })
  }

  /**
   * @Author HS
   * @Date 2021/8/18 6:50 下午
   * @Description: 切换单元
   * @Params: { number  index - true } [下标]
   * @Return: null
  */
  menuSwitchFn(index:number){
    const _this:any = this;
    _this.menuIndex = index
  }


  /**
   * @Author HS
   * @Date 2021/8/12 6:22 下午
   * @Description: 主单元产品类型
   * @Params: { string ： iType - true } [类型]
   * @Return: null
  */
  async GetByDictTypeListFn(iType:string){
    const _this:any = this;
    let params:GetByDictTypeListReq = {
      dictType:iType
    }
    const res = await GetByDictTypeListApi(params)
    const { data, code, msg } = res
    if(code != 200 ){
      _this.$message.error(msg);
      return
    }
    if(iType == 'commodity_type_cxjj'){
      _this.mainMenuList = [..._this.mainMenuList,...data]
      return
    }
    if(iType == 'commodity_cxjj_shape_type'){
      _this.xiuMenuList = [..._this.xiuMenuList,...data]
      return
    }
  }





}
